import Cyclone from 'cyclone'
import React from 'react'
import {
  TeamOutlined,
  ShopOutlined,
  BulbOutlined,
  EnvironmentOutlined,
  BarcodeOutlined,
  ShoppingOutlined,
  ShoppingCartOutlined,
  ToolOutlined,
  SettingOutlined,
  DashboardOutlined,
  FileTextOutlined,
  SwapOutlined,
  HomeOutlined
} from '@ant-design/icons'

// Redux and Saga
import rootReducer from '../redux'
import rootSaga from '../saga'

// Components
// import Root from '../root'
import Images from '../assets/images'

// I18n and Less
import './App.less'
import './App.i18n'

function App() {
  const roles = {
    UBIADMIN: 0,
    SUPERADMIN: 10,
    ADMIN: 20,
    MANAGER: 30
  }

  // Item properties
  const routesProps = {
    order: [
      'dashboard',
      'sales',
      'sales/receipts',
      'products',
      'warehouses',
      'sellers',
      'users',
      'parameters',
      'parameters/zones',
      'parameters/models'
    ],
    items: {
      dashboard: {
        icon: <DashboardOutlined />,
        roles: [roles.UBIADMIN, roles.SUPERADMIN, roles.ADMIN, roles.MANAGER]
      },
      users: {
        icon: <TeamOutlined />,
        roles: [roles.UBIADMIN, roles.SUPERADMIN, roles.ADMIN]
      },
      sellers: {
        icon: <ShopOutlined />,
        roles: [roles.UBIADMIN, roles.SUPERADMIN, roles.ADMIN]
      },
      sales: {
        crumbDisabled: true,
        icon: <ShoppingOutlined />,
        roles: [roles.UBIADMIN, roles.SUPERADMIN, roles.ADMIN, roles.MANAGER]
      },
      'sales/receipts': {
        icon: <ShoppingCartOutlined />,
        roles: [roles.UBIADMIN, roles.SUPERADMIN, roles.ADMIN, roles.MANAGER]
      },
      'sales/products': {
        icon: <BulbOutlined />,
        roles: [roles.UBIADMIN, roles.SUPERADMIN, roles.ADMIN, roles.MANAGER]
      },
      'sales/warehouses': {
        icon: <HomeOutlined />,
        roles: [roles.UBIADMIN, roles.SUPERADMIN, roles.ADMIN, roles.MANAGER]
      },
      'sales/transactions': {
        icon: <FileTextOutlined />,
        roles: [roles.UBIADMIN, roles.SUPERADMIN, roles.ADMIN, roles.MANAGER]
      },
      'sales/transaction-files': {
        icon: <FileTextOutlined />,
        roles: [roles.UBIADMIN, roles.SUPERADMIN, roles.ADMIN, roles.MANAGER]
      },
      'sales/clients': {
        icon: <TeamOutlined />,
        roles: [roles.UBIADMIN, roles.SUPERADMIN, roles.ADMIN, roles.MANAGER]
      },
      'sales/replacements': {
        icon: <SwapOutlined />,
        roles: [roles.UBIADMIN, roles.SUPERADMIN, roles.ADMIN, roles.MANAGER]
      },
      parameters: {
        crumbDisabled: true,
        icon: <SettingOutlined />,
        roles: [roles.UBIADMIN, roles.SUPERADMIN, roles.ADMIN]
      },
      'parameters/models': {
        icon: <BarcodeOutlined />,
        roles: [roles.UBIADMIN, roles.SUPERADMIN, roles.ADMIN]
      },
      'parameters/configuration': {
        icon: <ToolOutlined />,
        roles: [roles.UBIADMIN, roles.SUPERADMIN, roles.ADMIN]
      },
      'parameters/zones': {
        icon: <EnvironmentOutlined />,
        roles: [roles.UBIADMIN, roles.SUPERADMIN, roles.ADMIN]
      }
    }
  }
  const headerProps = {}
  const footerProps = {}

  // Cyclone config
  const config = {
    version: process.env.REACT_APP_VERSION,
    app: 'BACK_OFFICE',
    landingPage: 'dashboard',
    api: process.env.REACT_APP_API,
    logo: Images.icon,
    login_field_name: 'login'
  }

  return (
    <Cyclone
      config={config}
      routesProps={routesProps}
      headerProps={headerProps}
      footerProps={footerProps}
      appReducer={rootReducer}
      appSaga={rootSaga}
    >
      {/* <Root /> */}
    </Cyclone>
  )
}

export default App
