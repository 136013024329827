import i18next from 'i18next'
import { defaultTo } from 'lodash'

// Get values from env
const REACT_APP_DESCRIPTION =
  process.env.REACT_APP_DESCRIPTION === ''
    ? null
    : process.env.REACT_APP_DESCRIPTION
const REACT_APP_NAME =
  process.env.REACT_APP_NAME === '' ? null : process.env.REACT_APP_NAME

// en
i18next.addResourceBundle('en', 'Common', {
  app: {
    name: defaultTo(REACT_APP_NAME, 'Cyclone App'),
    title: defaultTo(REACT_APP_NAME, 'Cyclone App'),
    description: defaultTo(REACT_APP_DESCRIPTION, 'Automatically generated')
  }
})

i18next.addResourceBundle('en', 'Page', {})

// fr
i18next.addResourceBundle('fr', 'Common', {
  app: {
    name: defaultTo(REACT_APP_NAME, 'Cyclone App'),
    title: defaultTo(REACT_APP_NAME, 'Cyclone App'),
    description: defaultTo(
      REACT_APP_DESCRIPTION,
      'Application générée automatiquement'
    )
  }
})

i18next.addResourceBundle('fr', 'Page', {
  parameters: {
    title: 'paramètres',
    plural: 'paramètres',
    singular: 'paramètre',

    configuration: {
      title: 'configuration',
      plural: 'configurations',
      singular: 'configuration'
    },

    'receiver-accounts': {
      title: 'comptes receveurs',
      plural: 'comptes receveurs',
      singular: 'compte receveur'
    },

    models: {
      title: 'modèles équipement',
      plural: 'modèles équipement',
      singular: 'modèle équipement'
    },

    zones: {
      title: 'zones',
      plural: 'zones',
      singular: 'zone'
    }
  },
  sales: {
    title: 'ventes',
    plural: 'ventes',
    singular: 'vente',

    transactions: {
      title: 'transactions',
      plural: 'transactions',
      singular: 'transaction'
    },
    'transaction-files': {
      title: 'fichiers de transactions',
      plural: 'fichiers de transactions',
      singular: 'fichier de transactions'
    },
    products: {
      title: 'équipements',
      plural: 'équipements',
      singular: 'équipement'
    },
    warehouses: {
      title: 'dépôts',
      plural: 'dépôts',
      singular: 'dépôt'
    },
    receipts: {
      title: 'factures',
      plural: 'factures',
      singular: 'facture'
    },
    clients: {
      title: 'clients',
      plural: 'clients',
      singular: 'client'
    },
    replacements: {
      title: 'échanges',
      plural: 'échanges',
      singular: 'échange'
    }
  },
  users: {
    title: 'utilisateurs BO',
    plural: 'utilisateurs BO',
    singular: 'utilisateur BO'
  },
  sellers: {
    title: 'vendeurs',
    plural: 'vendeurs',
    singular: 'vendeur'
  }
})
